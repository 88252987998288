.dashboard-content {
    display: flex;
    justify-content: space-evenly;
}

.card-content {
    width: 40vw;
    max-width: 60rem;

    overflow-x: auto;
    overflow-y: hidden;
}

.icon {
    height: 100%;
    font-size: 4rem;
    text-align: center;
}

@media screen and (max-width: 810px) {
    .dashboard-content {
        flex-direction: column;
        
        margin-top: 1rem;
    }
    
    .card-content {
        min-width: 100%;
    }
}